import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  Container,
  CardBody,
} from "reactstrap";
import classnames from "classnames";

import Header from "components/Headers/Header.js";

import "react-phone-input-2/lib/style.css";
import NFCList from "./nfc-list";
import CardSchemeList from "views/card-schemes/card-scheme-list";
const navItemCls = {
  minWidth: "120px",
  textAlign: "center",
  cursor: "pointer",
};
const CardManagement = (props) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <Container className="mt--7" fluid>
        <Card>
          <CardBody>
            <Nav tabs>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    toggle("1");
                  }}
                  style={{ padding: "10px" }}
                >
                  Card details
                </NavLink>
              </NavItem>
              <NavItem style={navItemCls}>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    toggle("2");
                  }}
                  style={{ padding: "10px" }}
                >
                  Card Scheme
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <NFCList />
              </TabPane>

              <TabPane tabId="2">
                <CardSchemeList />
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default CardManagement;
