import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import {
  checkSpace,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import SearchableSelect from "components/dropdown/SearchDropdown";

const initialValue = {
  customerId: 0,
  nfcCardName: "",
  nfcCardID: "",
  nfcCardClientID: "",
  createdBy: "",
  updatedBy: "",
  schemeId: 0,
  cardSchemeName: "",
  isBlock: false,
  isDelete: false,
};

const AddNFC = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [customers, setCustomers] = React.useState([]);
  const [allCards, setAllCards] = React.useState([]);
  const [cardGroups, setCardGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    fetchNFC();
    fetchCardNames();
    fetchGroupNames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const fetchNFC = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/FSP/Profile/Customers", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCustomers(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchCardNames = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/GetAllNFCCardNames", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setAllCards(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const fetchGroupNames = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/CardSchemes/GetAll", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setCardGroups(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const {
      nfcCardName,
      customerId,
      nfcCardID,
      nfcCardClientID,
      isBlock,
      isDelete,
      cardSchemeName,
      schemeId,
    } = values;
    const payload = {
      nfcCardID,
      nfcCardName,
      customerId: Number(customerId),
      createdBy: getUserName(),
      updatedBy: getUserName(),
      nfcCardClientID,
      cardSchemeName,
      schemeId,
      isBlock,
      isDelete,
    };
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/SmartPayCard/Register", payload, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Saved Successfully");
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add NFC</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nfcCardName"
                        >
                          NFC card name
                        </label>
                        <SearchableSelect
                          options={allCards?.map((i) => ({
                            key: i?.cardName,
                            value: i?.cardName,
                          }))}
                          onSelect={(e) =>
                            setValues({ ...values, nfcCardName: e.key })
                          }
                          dropdownVersion={2}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-customerId"
                          className="form-control-label"
                        >
                          Card Scheme
                        </label>{" "}
                        <SearchableSelect
                          options={cardGroups
                            .filter((i) => !i.isDeleted && !i.isBlocked)
                            ?.map((i) => ({
                              key: i?.id,
                              value: i?.cardSchemeName,
                            }))}
                          onSelect={(e) =>
                            setValues({
                              ...values,
                              schemeId: e.key,
                              cardSchemeName: e.value,
                            })
                          }
                          dropdownVersion={2}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          for="input-customerId"
                          className="form-control-label"
                        >
                          Customer
                        </label>{" "}
                        <SearchableSelect
                          options={customers
                            .filter(
                              (i) =>
                                !i.isDeleted &&
                                !i.isBlocked &&
                                i.verificationStatus === "Verified"
                            )
                            ?.map((i) => ({
                              key: i?.id,
                              value: i?.fullName,
                            }))}
                          onSelect={(e) =>
                            setValues({ ...values, customerId: e.key })
                          }
                          dropdownVersion={2}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nfcCardID"
                        >
                          NFC card ID *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-nfcCardID"
                          placeholder="NFC Card ID"
                          type="text"
                          required
                          name="nfcCardID"
                          value={values.nfcCardID}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nfcCardClientID"
                        >
                          NFC card serial number *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-nfcCardClientID"
                          placeholder="NFC Card ID"
                          type="text"
                          required
                          name="nfcCardClientID"
                          maxLength={18}
                          value={values.nfcCardClientID}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNFC;
