import React, { useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
} from "reactstrap";

import { useToasts } from "react-toast-notifications";
import {
  checkSpace,
  getUserName,
  postMethod,
} from "../../services/httpServices";
import SearchableSelect from "components/dropdown/SearchDropdown";
import PhoneInput from "react-phone-input-2";

const initialValue = {
  cardSchemeName: "",
  issuerName: "",
  issuerAddress: "",
  issuerPhone: "",
  issuerEmail: "",
  accountStatus: "",
  cardApplication: "",
  groupId: 0,
  groupName: "",
  pinRule: "",
  createdBy: "",
  id: 0,
};

const AddNFC = (props) => {
  const [values, setValues] = React.useState(initialValue);
  const [groups, setGroups] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const { addToast } = useToasts();

  useEffect(() => {
    fetchGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeState = (obj) => {
    setValues(obj);
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const closeModal = () => {
    props.closeModal();
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (checkSpace(value)) {
      changeState({ ...values, [name]: value });
    }
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values,
        issuerPhone: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const fetchGroups = async () => {
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/POS/Groups/GetAll", {}, "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200" && res.data.result) {
            // Success
            if (!res.data.result || !res.data.result.length) {
              res.data.result = [];
            }
            setGroups(res.data.result);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.issuerPhone) {
      errors.issuerPhone = "Phone is required";
    }
    if (!inputs.pinRule) {
      errors.pinRule = "PIN Rule is required";
    }
    if (!inputs.accountStatus) {
      errors.accountStatus = "Account Status is required";
    }
    if (!inputs.cardApplication) {
      errors.cardApplication = "Card application is required";
    }
    if (inputs.cardApplication === "Closed-Loop" && !inputs.groupName) {
      errors.groupName = "Group Name is required";
    }

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...values,
      createdBy: getUserName(),
      createdDate: new Date(),
    };
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    let errorMsg = "";
    setLoading(true);
    postMethod("/api/CardSchemes/Add", payload, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            notifySuccess("Saved Successfully");
            closeModal();
          } else if (res.data.responseCode === "400") {
            // Error while saving
            errorMsg = res.data.responseMessage || "Error while saving";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in saving";
            notifyFailed(errorMsg);
          }
        } else {
          // Not valid response from server
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        errorMsg = "Something went wrong!, Please call 3737";
        notifyFailed(errorMsg);
      });
  };

  return (
    <div className="p-0">
      <Row>
        <Col xl="12">
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">Add Card Scheme</h3>
                </Col>
                <Col className="text-right" xs="4">
                  <Button
                    color="primary"
                    onClick={closeModal}
                    size="xs"
                    close
                    // className="fa fa-times"
                    style={{ cursor: "pointer", fontSize: "25px" }}
                  ></Button>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit}>
                <div className="pl-lg-4">
                  <Row>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-cardSchemeName"
                        >
                          Card Scheme Name *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-cardSchemeName"
                          placeholder="Card Scheme Name"
                          type="text"
                          required
                          name="cardSchemeName"
                          value={values.cardSchemeName}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6"></Col>
                    <Col lg="12">
                      <hr />
                    </Col>
                    <Col lg="12">
                      <h5>Issuer Information</h5>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-issuerName"
                        >
                          Name *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-issuerName"
                          placeholder="Name"
                          type="text"
                          required
                          name="issuerName"
                          value={values.issuerName}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-issuerEmail"
                        >
                          Email *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-issuerEmail"
                          placeholder="Email"
                          type="email"
                          required
                          name="issuerEmail"
                          value={values.issuerEmail}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-adminContactMobileNo"
                        >
                          Phone No *
                        </label>
                        <PhoneInput
                          className="form-control-alternative"
                          country={"sl"}
                          name="issuerPhone"
                          required
                          onChange={phoneChange}
                        />
                        {errors.issuerPhone && (
                          <div className="text-left text-danger">
                            <small>* {errors.issuerPhone}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-issuerAddress"
                        >
                          Address *
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-issuerAddress"
                          placeholder="Address"
                          type="textarea"
                          required
                          name="issuerAddress"
                          value={values.issuerAddress}
                          onChange={handleInputChange}
                        />
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <hr />
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-nfcCardName"
                        >
                          PIN Rule *
                        </label>
                        <SearchableSelect
                          options={[
                            {
                              key: "No-PIN",
                              value: "No-PIN",
                            },
                            {
                              key: "Tiered-PIN",
                              value: "Tiered-PIN",
                            },
                            {
                              key: "All-PIN",
                              value: "All-PIN",
                            },
                          ]}
                          onSelect={(e) =>
                            setValues({ ...values, pinRule: e.key })
                          }
                          dropdownVersion={2}
                        />
                        {errors.pinRule && (
                          <div className="text-left text-danger">
                            <small>* {errors.pinRule}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-accountStatus"
                        >
                          Account Status *
                        </label>
                        <SearchableSelect
                          options={[
                            {
                              key: "Standalone",
                              value: "Standalone",
                            },
                            {
                              key: "Linked",
                              value: "Linked",
                            },
                          ]}
                          onSelect={(e) =>
                            setValues({ ...values, accountStatus: e.key })
                          }
                          dropdownVersion={2}
                        />
                        {errors.accountStatus && (
                          <div className="text-left text-danger">
                            <small>* {errors.accountStatus}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <label
                          className="form-control-label"
                          htmlFor="input-cardApplication"
                        >
                          Card Application *
                        </label>
                        <SearchableSelect
                          options={[
                            {
                              key: "Closed-Loop",
                              value: "Closed-Loop",
                            },
                            {
                              key: "Open Access",
                              value: "Open Access",
                            },
                          ]}
                          onSelect={(e) =>
                            setValues({ ...values, cardApplication: e.key })
                          }
                          dropdownVersion={2}
                        />
                        {errors.cardApplication && (
                          <div className="text-left text-danger">
                            <small>* {errors.cardApplication}</small>
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    {values.cardApplication === "Closed-Loop" && (
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-groupName"
                          >
                            POS Group *
                          </label>
                          <SearchableSelect
                            onSelect={(e) =>
                              setValues({
                                ...values,
                                groupId: e.key,
                                groupName: e.value,
                              })
                            }
                            dropdownVersion={2}
                            options={groups
                              .filter((i) => !i.isDeleted && !i.isBlocked)
                              ?.map((i) => ({
                                key: i?.id,
                                value: i?.groupName,
                              }))}
                          />
                          {errors.groupName && (
                            <div className="text-left text-danger">
                              <small>* {errors.groupName}</small>
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    )}
                  </Row>
                </div>

                <Button
                  type="submit"
                  color="primary"
                  className="mt-4 float-right"
                  disabled={loading}
                >
                  {loading ? "Loading..." : "Submit"}
                </Button>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default AddNFC;
