import React, { useEffect, useState } from "react";

// reactstrap components
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
// core components

import Header from "components/Headers/Header";
import { getMethod } from "services/httpServices";
import { useToasts } from "react-toast-notifications";
import PhoneInput from "react-phone-input-2";
import { postMethod } from "services/httpServices";
import ShieldGif from "../../assets/gif/shield.gif";

const initialValue = {
  type: "",
  fspid: null,
  phone: null,
  customerId: null,
  accountNo: null,
  email: null,
};

const CustomerWhitelist = (props) => {
  const [values, setValues] = useState(initialValue);
  const [errors, setErrors] = useState({});
  const [fsps, setFsps] = useState([]);
  const [loading, setLoading] = React.useState(false);
  const [accounts, setAccounts] = useState([]);
  const [isLinkSent, setIsLinkSent] = useState(false);
  const [selectedAcc, setSelectedAcc] = useState(null);
  const [fspCategory, setFspCategory] = useState(null);
  const [isDirectDone, setIsDirectDone] = useState(false);
  const [linkMsg, setLinkMsg] = useState("");
  const { addToast } = useToasts();

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  useEffect(() => {
    fetchAllFsps();
  }, []);

  const fetchAllFsps = async () => {
    let errorMsg = "";
    setLoading(true);
    getMethod("/api/FSP/GetAll", "get")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            if (!res.data.result || !res.data?.result?.length) {
              setFsps([]);
            } else {
              setFsps(res.data.result);
            }
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg = res.data.responseMessage || "Error in fetching list";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  const changeState = (obj) => {
    setValues(obj);
  };
  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      changeState({
        ...values,
        mobileCodeId: data.dialCode,
        phone: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  function hasAnyField(inputs) {
    return inputs.phone || inputs.email || inputs.customerId || inputs.accountNo
      ? true
      : false;
  }

  const validate = (inputs) => {
    let errors = {};
    if (!inputs.fspid) {
      errors.fspName = "Fsp  is required";
    }
    if (!hasAnyField(inputs)) {
      errors.phone =
        "Email, either Mobile No. or Account No, Customer ID any one filed is required";
    } else if (inputs.customerId && !inputs.phone) {
      errors.phone = "Mobile No. is required";
    }

    return errors;
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const handleSubmit = (e) => {
    e?.preventDefault();
    const validationErrors = validate(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (!isNoError) {
      return;
    }
    const { type, fspName, mobileCodeId, ...rest } = values;
    setLoading(true);
    const genLinkURL = "/api/FSP/Profile/Customer/GenerateLink";
    const directWhiteList = "/api/FSP/Profile/Customer/Whitelist";
    const url = type === "direct" ? directWhiteList : genLinkURL;
    postMethod(
      url,
      {
        ...rest,
        fspid: String(values.fspid),
        ...(accounts?.length > 1 && {
          accountNo: accounts.find((i) => i.accountNo === selectedAcc)
            ?.accountNo,
        }),
      },
      "post"
    )
      .then((res) => {
        setLoading(false);
        if (res.data.responseCode === "200" && type !== "direct") {
          setIsLinkSent(true);
          return;
        } else {
          setIsLinkSent(false);
        }
        if (
          res.data.responseCode === "200" &&
          res?.data?.result?.customerAcct
        ) {
          setAccounts(res?.data?.result?.customerAcct);
          if (res?.data?.result?.customerAcct?.length === 1) {
            setIsDirectDone(true);
          }
        } else if (res.data?.result?.accountNo) {
          const message = res?.data?.result?.errMsg || "Success";
          setIsDirectDone(true);
          if (accounts?.length) {
            let findAcc = accounts?.find(
              (i) => i.accountNo === res.data?.result?.accountNo
            );
            if (findAcc) {
              setAccounts([findAcc]);
            }
          } else {
            setAccounts([res.data?.result]);
          }
          setLinkMsg(message);
          notifySuccess(message);
        } else {
          if (res.data.responseCode === "200" && res?.data?.result) {
            const errorMsg = res?.data?.result || "Link sent successfully";
            setLinkMsg(errorMsg);
            setIsLinkSent(true);
            notifySuccess(errorMsg);
          } else {
            const errorMsg = "Data does not exists!";
            notifyFailed(errorMsg);
          }
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Something went wrong!, Please call 3737");
      });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row className="justify-content-center">
          <Col xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Whitelist</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="search-trans-cls">
                <Form onSubmit={handleSubmit}>
                  {accounts.length === 0 && !isLinkSent && (
                    <>
                      <Row>
                        <Col lg="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="type"
                                value="link"
                                checked={values.type === "link"}
                                onChange={handleInputChange}
                              />{" "}
                              Generate Link
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col lg="6">
                          <FormGroup check>
                            <Label check>
                              <Input
                                type="radio"
                                name="type"
                                value="direct"
                                checked={values.type === "direct"}
                                onChange={handleInputChange}
                              />{" "}
                              Direct Whitelist
                            </Label>
                          </FormGroup>
                        </Col>
                      </Row>
                    </>
                  )}
                  {values.type && accounts.length === 0 && !isLinkSent && (
                    <div className="pt-4">
                      <>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-fspName"
                              >
                                FSP Name *
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-fspName"
                                placeholder="FSP Name"
                                type="select"
                                name="fspName"
                                required
                                value={values.fspName}
                                onChange={(e) => {
                                  setFspCategory(
                                    fsps.find(
                                      (i) => i.fspName === e.target.value
                                    ).fspCategory
                                  );
                                  setValues({
                                    ...values,
                                    fspName: e.target.value,
                                    fspid: fsps.find(
                                      (i) => i.fspName == e.target.value
                                    )?.id,
                                  });
                                }}
                              >
                                <option key="" value="">
                                  ------- Select -------
                                </option>
                                {fsps?.map((item) => (
                                  <option key={item.id} value={item.fspName}>
                                    {item?.fspName}
                                  </option>
                                ))}
                              </Input>
                              {errors.fspName && (
                                <div className="text-left text-danger">
                                  <small>* {errors.fspName}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-adminContactMobileNo"
                              >
                                {fspCategory &&
                                fspCategory?.toLowerCase() === "bank"
                                  ? "Mobile No *"
                                  : "Wallet Number *"}
                              </label>
                              <PhoneInput
                                className="form-control-alternative"
                                country={"sl"}
                                name="adminContactMobileNo"
                                required
                                onChange={phoneChange}
                              />
                            </FormGroup>
                          </Col>
                          {fspCategory &&
                            fspCategory?.toLowerCase() === "bank" && (
                              <>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-customerId"
                                    >
                                      Customer Id
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-customerId"
                                      placeholder="Customer Id"
                                      type="text"
                                      name="customerId"
                                      value={values.customerId}
                                      onChange={handleInputChange}
                                    />
                                    {errors.customerId && (
                                      <div className="text-left text-danger">
                                        <small>* {errors.customerId}</small>
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="6">
                                  <FormGroup>
                                    <label
                                      className="form-control-label"
                                      htmlFor="input-accountNo"
                                    >
                                      Account No
                                    </label>
                                    <Input
                                      className="form-control-alternative"
                                      id="input-accountNo"
                                      placeholder="Account No"
                                      type="text"
                                      name="accountNo"
                                      value={values.accountNo}
                                      onChange={handleInputChange}
                                    />
                                    {errors.accountNo && (
                                      <div className="text-left text-danger">
                                        <small>* {errors.accountNo}</small>
                                      </div>
                                    )}
                                  </FormGroup>
                                </Col>
                              </>
                            )}

                          <Col lg="6">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-email"
                              >
                                Email
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-email"
                                placeholder="Email"
                                type="email"
                                name="email"
                                value={values.email}
                                onChange={handleInputChange}
                              />
                              {errors.email && (
                                <div className="text-left text-danger">
                                  <small>* {errors.email}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>
                      </>
                      {errors.phone && (
                        <div className="text-left text-danger">
                          <small>* {errors.phone}</small>
                        </div>
                      )}
                      <Alert color="info">
                        {values.type === "link" && (
                          <span>
                            To whitelist a customer, you will need their mobile
                            number, email, and FSP (Financial Service Provider)
                            selection. Once these details are provided, a link
                            will be sent to the customer. The customer must fill
                            out the required information, which will be verified
                            in real-time with the FSP system. If the provided
                            details match, an OTP will be sent to the customer,
                            and they will be prompted to set a PIN.
                          </span>
                        )}

                        {values.type === "direct" && (
                          <span>
                            If the FSP category or type is 'Bank', and the
                            customer only knows their Customer ID, you as the
                            admin can select the FSP and input the Customer ID.
                            The system will retrieve the customer’s information
                            (mobile and email) from the FSP system based on the
                            provided Customer ID. An OTP and a PIN setup link
                            will be sent to the customer using the retrieved
                            mobile number and email. The same process applies if
                            you use the Account Number/ID or Mobile Number.
                          </span>
                        )}
                      </Alert>
                      <Row>
                        <Col lg={"12"} className="d-flex justify-content-end">
                          <Button
                            type="submit"
                            color="primary"
                            className="mt-4 float-right"
                            disabled={loading}
                          >
                            {loading ? "Loading..." : "Submit"}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {isLinkSent && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <div className="d-flex justify-content-center align-items-center w-100">
                        {/* <img
                          src={ShieldGif}
                          alt="verify"
                          className="h-25 w-25"
                        /> */}
                        <Alert color="info">
                          <span>{linkMsg || "Link sent successfully!"}</span>
                        </Alert>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          type="button"
                          onClick={() => {
                            setValues(initialValue);
                            setErrors({});
                            setAccounts([]);
                            setIsLinkSent(false);
                            setLinkMsg("")
                          }}
                          className="w-100"
                          color="primary"
                        >
                          Go Back
                        </Button>
                      </div>
                    </div>
                  )}

                  {accounts.length > 1 && (
                    <div className="">
                      {/* <div className="d-flex justify-content-center align-items-center w-100">
                        <img
                          src={ShieldGif}
                          alt="verify"
                          className="h-25 w-25"
                        />
                        <p>
                          Verification has started, and you have received the
                          OTP to complete the verification on your
                          bank-registered email and phone.
                        </p>
                      </div> */}
                      <div className="d-flex border-bottom pb-2 mb-2 align-items-center">
                        <i
                          class="fas fa-arrow-left"
                          onClick={() => setAccounts([])}
                          style={{
                            cursor: "pointer",
                          }}
                        ></i>
                        <h2 className="font-bold mb-0 ml-2">
                          Direct Whitelist
                        </h2>
                      </div>

                      <p className="font-bold mb-0">Account Details</p>
                      <p className="font-normal text-sm">
                        Please select one account to Whitelist
                      </p>

                      {accounts?.map((item, i) => (
                        <div
                          key={i}
                          className="border rounded p-4 mb-2 table-hover"
                          style={{
                            cursor: "pointer",
                            background:
                              selectedAcc === item.accountNo
                                ? "#f0ffff"
                                : "white",
                          }}
                          onClick={() => setSelectedAcc(item.accountNo)}
                        >
                          <div className="d-flex justify-content-between">
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              Account Name
                            </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item?.accountName ?? "-"}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              Account No
                            </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item?.accountNo ?? "-"}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              Email
                            </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item?.email ?? "-"}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              Available Balance
                            </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item?.avlBal ?? "-"}
                            </p>
                          </div>
                          <div className="d-flex justify-content-between">
                            <p
                              style={{
                                fontWeight: "normal",
                                fontSize: "14px",
                                margin: "0px",
                              }}
                            >
                              Product
                            </p>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                              {item?.product ?? "-"}
                            </p>
                          </div>
                          {/* <Button
                            type="button"
                            onClick={() => {
                              setValues(initialValue);
                              setErrors({});
                              setAccounts([]);
                            }}
                            color="primary"
                          >
                            Go Home
                          </Button> */}
                        </div>
                      ))}

                      {selectedAcc && (
                        <Button
                          type="button"
                          onClick={handleSubmit}
                          color="primary"
                          className="float-right"
                          disabled={loading}
                        >
                          {loading ? "Loading..." : "Whitelist"}
                        </Button>
                      )}
                    </div>
                  )}

                  {(accounts.length === 1 || isDirectDone) && (
                    <div className="">
                      <div className="d-flex justify-content-center align-items-center w-100">
                        <img
                          src={ShieldGif}
                          alt="verify"
                          className="h-25 w-25"
                        />
                        <p>
                          Verification has started, and you have received the
                          OTP to complete the verification on your
                          bank-registered email and phone.
                        </p>
                      </div>

                      <p className="font-bold mb-0">Account Details</p>
                      <div className="border rounded p-4 mb-2 table-hover">
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              margin: "0px",
                            }}
                          >
                            Account Name
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {accounts[0]?.accountName ?? "-"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              margin: "0px",
                            }}
                          >
                            Account No
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {accounts[0]?.accountNo ?? "-"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              margin: "0px",
                            }}
                          >
                            Email
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {accounts[0]?.email ?? "-"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              margin: "0px",
                            }}
                          >
                            Available Balance
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {accounts[0]?.avlBal ?? "-"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p
                            style={{
                              fontWeight: "normal",
                              fontSize: "14px",
                              margin: "0px",
                            }}
                          >
                            Product
                          </p>
                          <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                            {accounts[0]?.product ?? "-"}
                          </p>
                        </div>
                        <Button
                          type="button"
                          onClick={() => {
                            setValues(initialValue);
                            setErrors({});
                            setAccounts([]);
                            setSelectedAcc(null);
                            setIsDirectDone(false);
                          }}
                          color="primary"
                          className="w-100 float-right"
                        >
                          Go Home
                        </Button>
                      </div>
                    </div>
                  )}
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CustomerWhitelist;
